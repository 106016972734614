// src/About.js
import './About.css';

function About() {
  return (
    <div className="About">
      <h1>About Us</h1>
      <p>This is the About page of our website.</p>
    </div>
  );
}

export default About;
