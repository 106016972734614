// src/HostileArchitecturePage.js
import React, { useState } from "react";
import "./HostileArchitecturePage.css";
//import BackButton from "./components/BackButtonBlack";


function HostileArchitecturePage() {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="hostile-architecture-page">
      {/*<BackButton />*/}
      <h1 className="hostile-architecture-title">
        Hostile Architecture and Inaccessibility
      </h1>
      <div className="media-container">
        {showVideo ? (
          <iframe
            src="https://www.youtube.com/embed/9CyK23N3yv4?si=FvAdT7jTlAUPNA5R"
            title="Hostile Architecture and Inaccessibility"
            className="work-video"
            allowFullScreen
          ></iframe>
        ) : (
          <img
            src="/pwr.png"
            alt="Hostile Architecture and Inaccessibility"
            className="work-imageh"
          />
        )}
        <button
          className="toggle-media-btn"
          onClick={() => setShowVideo(!showVideo)}
        >
          {showVideo ? "Show Image" : "Show Video"}
        </button>
      </div>
      <div className="embed-container">
        <iframe
          src="https://drive.google.com/file/d/1pAcudZ42SkV_HUgu7IYz-_9AQSPupr0d/preview"
          title="Hostile Architecture Document"
          className="embed-iframe"
          allowFullScreen
        ></iframe>
      </div>

      <div className="hostile-architecture-content">
        <hr className="divider" />
        <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">2024</span>
            </div>
            <div className="project-detail">
              <span className="label">Disciplines:</span>
              <span className="value">Research, Argumentative Writing</span>
            </div>
            <div className="project-detail">
              <span className="label">Summary:</span>
              <span className="value">Final research paper for PWR2RL </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default HostileArchitecturePage;
