// src/EclipsePage.js
import React from "react";
import "./MovieGamePage.css";
//import { Link } from "react-router-dom";

function MovieGamePage() {
  return (
    <div className="movie-game-page">
      <h1 className="movie-game-title">Actor to Actor</h1>
      <div className="movie-image-container">
        <img
          src="/actormockup9.png"
          alt="Actor to Actor game"
          className="movie-image"
        />
        {/* Add the button inside the movie-image-container */}
        {/* Replace Link with <a> tag to open in new tab */}
        <a
          href="https://actortoactor.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="play-game-button">Play the Game</button>
        </a>
      </div>

      <div className="movie-game-content">
        <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">June 2024 - October 2024</span>
            </div>
            <div className="project-detail">
              <span className="label">Tools:</span>
              <span className="value">Python, JavaScript, Render, Netlify</span>
            </div>
            <div className="project-detail">
              <span className="label">Disciplines:</span>
              <span className="value">Software Engineering, Game Design</span>
            </div>
            <div className="project-detail">
              <span className="label">Role:</span>
              <span className="value">
                Full-stack Developer, UI/UX Designer
              </span>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="movie-game-description">
          <h2>What It Is</h2>
          <p>
            The Actor to Actor Game is a web app where players attempt to
            connect two actors through a series of movies and co-stars. The game
            calculates the shortest path between a starting actor and a target
            actor using data on movies and actors. Players must guess which
            actor comes next in the path based on the current movie. The
            challenge comes from the fact that if the player guesses
            incorrectly, the game recalculates a longer path from the incorrect
            actor to the target actor, dynamically adjusting the difficulty as
            they make progress.
          </p>
          <h2>Game Inspiration</h2>
          <p>
            This project is inspired by the "Six Degrees of Kevin Bacon" theory,
            which suggests that any actor can be connected to Kevin Bacon within
            six or fewer degrees of separation through their movie co-stars.
            Drawing from this concept, I developed an interactive game where
            users connect two randomly chosen actors by guessing the shortest
            path of co-stars and movies. The game is designed to test the
            player’s movie knowledge and logical thinking while navigating the
            network of actors and films.
          </p>

          <h2>How It's Coded</h2>

          <h3>Backend (Flask)</h3>
          <p>
            The backend of the game is built with Flask, which handles the game
            logic, API endpoints, and manages game state across user sessions.
            It generates the actors, calculates the shortest paths, processes
            user guesses, and recalculates paths when needed.
          </p>

          <h3>Shortest Path Calculation (Dijkstra's Algorithm)</h3>
          <p>
            The core game mechanic revolves around calculating the shortest path
            between two actors, which is achieved using Dijkstra's algorithm.
            The relationships between actors and movies are modeled as a graph,
            where the actors and movies are nodes, and edges represent
            connections through movie collaborations.
          </p>

          <h3>Data Storage (Pickle)</h3>
          <p>
            A preprocessed graph containing actors and movies is stored in a
            pickle file for efficient retrieval and manipulation during
            gameplay. The graph is built using Python’s NetworkX library, where
            each node represents an actor or movie, and edges represent the
            connections between them.
          </p>

          <h3>Image Fetching (TMDb API)</h3>
          <p>
            Actor images are fetched using The Movie Database (TMDb) API. This
            provides a visually engaging experience for players, as they can see
            the actors associated with each step in the path.
          </p>

          <h3>Frontend (React)</h3>
          <p>
            The game’s user interface is built using React. It provides a
            responsive and interactive experience, where players can easily
            navigate through the game. The frontend communicates with the Flask
            backend via API calls to fetch the actors, movies, and game status.
          </p>

          <h3>Hint System (OpenAI GPT)</h3>
          <p>
            If players are stuck, they can request hints generated by OpenAI's
            GPT model, which offers subtle clues about the next actor in the
            path without giving away the full answer.
          </p>

          <h2>Notable Features</h2>

          <h3>Dynamic Path Updates</h3>
          <p>
            When players make an incorrect guess, the game recalculates the path
            from the guessed actor to the target actor, making the game more
            challenging and dynamic.
          </p>

          <h3>Hint System</h3>
          <p>
            Players can request hints if they are struggling to guess the next
            actor. The hints are generated using OpenAI’s GPT model, providing
            clues that aren’t too obvious but still helpful.
          </p>

          <h3>Actor Images</h3>
          <p>
            To enhance the gameplay experience, actor images are fetched from
            TMDb’s API, giving players a visually engaging experience as they
            guess through the game.
          </p>

          <h3>Smooth Frontend-Backend Integration</h3>
          <p>
            The React frontend seamlessly communicates with the Flask backend
            through API calls, ensuring smooth gameplay and real-time updates.
          </p>

          <h2>Key Technologies</h2>

          <p>
            <strong>Python:</strong> Used to build the backend, manage game
            logic, and implement the shortest path algorithm.
          </p>
          <p>
            <strong>Flask:</strong> Serves as the backend framework, handling
            the game’s API, session management, and gameplay logic.
          </p>
          <p>
            <strong>NetworkX:</strong> Used to construct and manipulate the
            graph of actors and movies, where the shortest paths are calculated.
          </p>
          <p>
            <strong>Dijkstra’s Algorithm:</strong> The algorithm used to
            calculate the shortest path between two actors in the graph.
          </p>
          <p>
            <strong>React:</strong> Powers the interactive and responsive user
            interface, allowing users to engage with the game in real-time.
          </p>
          <p>
            <strong>TMDb API:</strong> Used to fetch images of actors and
            movies, enhancing the game’s visual appeal.
          </p>
          <p>
            <strong>OpenAI API:</strong> Provides the hint system, generating
            helpful clues for players who request assistance.
          </p>
          <p>
            <strong>Pickle:</strong> Used to store and retrieve the preprocessed
            graph of actors and movies efficiently.
          </p>

          {/* Add more content as needed */}
        </div>
      </div>
    </div>
  );
}

export default MovieGamePage;
