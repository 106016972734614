import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import CursorTrail from "./CursorTrail";
import Footer from "./components/Footer"; // Import the new Footer component

function WorkCard({
  imageSrc,
  title,
  description,
  isUnblurred,
  isHovered,
  link,
  index,
}) {
  const [cardHovered, setCardHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const currentRef = cardRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setIsVisible(true);
          }, index * 50);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [index]);

  return (
    <Link to={link} className="work-card-link">
      <div
        ref={cardRef}
        className={`work-card ${isUnblurred ? "unblurred" : ""} ${
          isVisible ? "visible" : ""
        }`}
        onMouseEnter={() => setCardHovered(true)}
        onMouseLeave={() => setCardHovered(false)}
      >
        <div
          className={`work-image-wrapper ${
            cardHovered || isUnblurred || isHovered ? "unblurred" : ""
          }`}
        >
          <img src={imageSrc} alt={title} className="work-image" />
        </div>
        <div
          className={`work-info ${
            cardHovered || isUnblurred || isHovered ? "unblurred" : ""
          }`}
        >
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </Link>
  );
}

function Home() {
  const [showAbout, setShowAbout] = useState(false);
  const toggleAbout = () => {
    setShowAbout(!showAbout);
  };
  /*
  const [charCases, setCharCases] = useState(() =>
    "ShUn UeNo".split("").map((char) => char === char.toUpperCase())
  ); // Store the case of each character

  

  const handleMouseEnter = (index) => {
    setCharCases((prevCases) =>
      prevCases.map((isUpperCase, i) =>
        i === index ? !isUpperCase : isUpperCase
      )
    );
  };

  const renderTitle = () => {
    const title = "ShUn UeNo";

    return (
      <h1 className="site-title">
        {title.split("").map((char, index) => (
          <span
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => {}} // No need to revert the case
            style={{ display: "inline-block" }}
          >
            {char === " " ? "\u00A0" : charCases[index] ? char.toUpperCase() : char.toLowerCase()}
          </span>
        ))}
      </h1>
    );
  };
  */
  return (
    <CursorTrail>
      <div className="home-container">
        <header className="app-header">
          <div className="header-content">
            <span onClick={toggleAbout} className="nav-link about-link">
              ABOUT
            </span>
            <div className="central-content">
              <h1 className="site-title">Shun Ueno</h1>
              {/*{renderTitle()}*/}
              <div className={`about-section ${showAbout ? "visible" : ""}`}>
                <p className="about-text">
                  Shun is a design-focused engineer, double majoring in Computer
                  Science and Design at Stanford University. He emphasizes
                  novelty in design and creative user interactions. He combines
                  his strong full-stack development skills with his passion for
                  design to enhance digital products and experiences.
                </p>
              </div>
            </div>
            <a href="#works" className="nav-link work-link">
              WORK
            </a>
            <a
              href="mailto:shunueno@stanford.edu"
              className="icon-link email-icon"
            >
              <img src="/black_email.png" alt="Email" />
            </a>
            <a
              href="https://linkedin.com/in/shunueno"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link linkedin-icon"
            >
              <img src="/linkedin.png" alt="LinkedIn" />
            </a>
          </div>
        </header>

        <hr className="divider" />

        <section id="works" className="works-section">
          <div className="works-container">
            {[
              {
                imageSrc: "/actormockup8.png",
                title: "Actor To Actor Game",
                link: "/works/actortoactor",
              },
              {
                imageSrc: "/radar2.png",
                title: "VALUENEX",
                link: "/works/valuenex",
              },
              {
                imageSrc: "/pwr_mockup.png",
                title: "Hostile Architecture and Inaccessibility",
                link: "/works/hostile-architecture",
              },
              {
                imageSrc: "/poster_mockup3.png",
                title: "ECLIPSE",
                link: "/works/eclipse",
              },
              {
                imageSrc: "/rockpath2.png",
                title: "Miscellaneous Side Projects",
                link: "/works/justforfun",
              },
              
              {
                imageSrc: "/frag2.png",
                title: "FRGNCE",
              },
            ].map((card, index) => (
              <WorkCard
                key={card.title}
                imageSrc={card.imageSrc}
                title={card.title}
                description={card.description}
                isUnblurred={true}
                isHovered={false}
                link={card.link}
                index={index}
              />
            ))}
          </div>
        </section>

        <hr className="divider" />

        <Footer />
      </div>
    </CursorTrail>
  );
}

export default Home;
