// src/EclipsePage.js
import React from "react";
import "./EclipsePage.css";
//import BackButton from "./components/BackButtonBlack";

function EclipsePage() {
  return (
    <div className="eclipse-page">
      {/*<BackButton />*/}
      <h1 className="eclipse-title">ECLIPSE</h1>
      <div className="gif-container">
        <img
          src="/eclipse2.gif"
          alt="Eclipse Animation"
          className="eclipse-gif"
        />
      </div>
      <div className="eclipse-content">
        <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">2024</span>
            </div>
            <div className="project-detail">
              <span className="label">Tools:</span>
              <span className="value">Figma, Illustrator, Photoshop</span>
            </div>
            <div className="project-detail">
              <span className="label">Disciplines:</span>
              <span className="value">Graphic Design, Poster Design</span>
            </div>
            <div className="project-detail">
              <span className="label">Summary:</span>
              <span className="value">Final project for DESIGN 170</span>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="eclipse-description">
          <p>
            {" "}
            Conceptualized the ECLIPSE poster series. Used Figma, Illustrator,
            and Photoshop to build style guides, branding motifs, and posters.
            The ECLIPSE poster series has a simple mission: to cast a spotlight
            on individuals who typically do not grace the glossy pages of
            mainstream posters. Posters have traditionally featured musicians,
            celebrities, and athletes, serving as icons of popular culture. But
            what about the intellectuals, the trailblazers, and the innovators
            whose work shapes our world in quiet, yet impactful ways? The term
            "Eclipse" signifies the overshadowing of these significant but less
            recognized contributions by more mainstream figures. Think Nobel
            Prize winners, visionary politicians, pioneering scientists, and
            unsung heroes from various disciplines – they are the centers of
            this project. I hope to highlight these individuals in a fresh,
            engaging format and to establish an educational element that not
            only decorates a space but also informs and enlightens its audience.
          </p>
          {/* Add more content as needed */}
          <hr className="divider" />

          <h2 className="poster-title">POSTERS</h2>
          <div className="image-text-container">
            <div>
              <img
                src="/Marie.png"
                alt="Marie Curie"
                className="section-image image-1"
              />
              <p className="section-text text-1">Marie Curie</p>
            </div>

            <div>
              <img
                src="/mission.png"
                alt="Mission"
                className="section-image image-2"
              />
              <p className="section-text text-2">Project Mission</p>
            </div>

            <div>
              <img
                src="/starter2.png"
                alt="Starter Collection"
                className="section-image image-3"
              />
              <p className="section-text text-3">Starter Collection</p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default EclipsePage;
