// src/VNPage.js
import React from 'react';
import './VNPage.css';
//import BackButton from './components/BackButtonBlack';



function VNPage() {
  return (
    <div className="vn-page">
        {/*<BackButton />*/}
        <h1 className="vn-title">VALUENEX</h1>
        <div className="vn-image-container">
        <img src="/vn_new2.png" alt="VN RADAR" className="vn-image" />
      </div>
      <div className="vn-content">
      <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">November 2023 - Present</span>
            </div>
            <div className="project-detail">
              <span className="label">Tools:</span>
              <span className="value">NLP, ML, Python</span>
            </div>
            <div className="project-detail">
              <span className="label">Disciplines:</span>
              <span className="value">Data Analysis, Consulting</span>
            </div>
            <div className="project-detail">
              <span className="label">Role:</span>
              <span className="value">Software Engineer and Data Analyst Intern</span>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="vn-description">
          <p>Software Engineering and Data Analyst internship at{' '}
    <a 
      href="https://en.valuenex.com/" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      VALUENEX, Inc
    </a>
    {''}. Main contributions include implementing Natural Language Processing and Cluster Mapping programs to provide predictive analytics and big data visualization for new business development strategies to C-level clients. 
    
    Additionally, I designed and developed a full-stack AI-driven web application for process optimization for multi-billion dollar corporations in the manufacturing industry.
    </p>
          {/* Add more content as needed */}
        </div>
      </div>
      
    </div>
  );
}

export default VNPage;