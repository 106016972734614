import React, { useEffect, useRef } from 'react';

const CursorTrail = ({ children }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const positionsRef = useRef([]);
  const requestIdRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    const ctx = canvas.getContext('2d');

    /*
    const resizeCanvas = () => {
      const { scrollWidth, scrollHeight } = document.documentElement;
      canvas.width = scrollWidth;
      canvas.height = scrollHeight;
      canvas.style.width = `${scrollWidth}px`;
      canvas.style.height = `${scrollHeight}px`;
    }; */
    const resizeCanvas = () => {
      const dpr = window.devicePixelRatio || 1;
      const { scrollWidth, scrollHeight } = document.documentElement;
    
      // Set the canvas width and height based on the pixel ratio
      canvas.width = scrollWidth * dpr;
      canvas.height = scrollHeight * dpr;
    
      // Apply the same styles so the canvas appears the right size visually
      canvas.style.width = `${scrollWidth}px`;
      canvas.style.height = `${scrollHeight}px`;
    
      // Scale the drawing context to match the pixel ratio
      ctx.scale(dpr, dpr);
    };

    const addPosition = (x, y) => {
      positionsRef.current.push({ x, y });
      if (positionsRef.current.length > Number.MAX_SAFE_INTEGER) {
        positionsRef.current.shift();
      }
    };

    const drawTrail = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      positionsRef.current.forEach((position) => {
        ctx.fillStyle = `rgba(150, 0, 0, 1)`;
        ctx.beginPath();
        ctx.arc(position.x, position.y, 3, 0, Math.PI * 2);
        ctx.fill();
      });
      requestIdRef.current = requestAnimationFrame(drawTrail);
    };

    const mouseMoveHandler = (event) => {
      const x = event.pageX;
      const y = event.pageY;
      addPosition(x, y);
    };

    // Create a ResizeObserver to watch for container size changes
    const resizeObserver = new ResizeObserver(() => {
      resizeCanvas();
    });

    resizeObserver.observe(container);

    window.addEventListener('resize', resizeCanvas);
    document.addEventListener('mousemove', mouseMoveHandler);

    // Initial resize and draw
    resizeCanvas();
    drawTrail();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      document.removeEventListener('mousemove', mouseMoveHandler);
      cancelAnimationFrame(requestIdRef.current);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={containerRef} style={{ position: 'relative', minHeight: '100vh' }}>
      {children}
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
          zIndex: 1,
        }}
      />
    </div>
  );
};

export default CursorTrail;